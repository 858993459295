import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    genericSort,
    formatName,
    createSurnameFellowsArray,
} from 'common/utils';
import { DUPLICATIONS_SCOPE } from 'common/constants';
import { Box } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const ScheduleNewColumn = ({ change, data, options, post, publishers }) => {
    const { t } = useTranslation();
    const weekIDs = [];
    const weekendIDs = [];

    const rowsList = [];

    const surnameFellows = createSurnameFellowsArray(publishers);

    const getDuplicateNum = (id, scope) => {
        if (!id || !scope) {
            return 0;
        }
        if (scope === DUPLICATIONS_SCOPE.WEEK) {
            return weekIDs.filter((weekID) => weekID === id).length;
        }
        if (scope === DUPLICATIONS_SCOPE.WEEKEND) {
            return weekendIDs.filter((weekID) => weekID === id).length;
        }
        if (scope === DUPLICATIONS_SCOPE.BOTH) {
            return Math.max(
                weekIDs.filter((weekID) => weekID === id).length,
                weekendIDs.filter((weekID) => weekID === id).length
            );
        }
    };

    data.forEach((row) => {
        if (row?.publisherID && row?.duplicationsScope) {
            if (
                row?.duplicationsScope === DUPLICATIONS_SCOPE.WEEK ||
                row?.duplicationsScope === DUPLICATIONS_SCOPE.BOTH
            ) {
                weekIDs.push(row?.publisherID);
            } else if (
                row?.duplicationsScope === DUPLICATIONS_SCOPE.WEEKEND ||
                row?.duplicationsScope === DUPLICATIONS_SCOPE.BOTH
            ) {
                weekendIDs.push(row?.publisherID);
            }
        }

        if (row?.type === 'select' || !row?.type) {
            rowsList.push(
                <Input
                    key={row.id}
                    type={'select'}
                    value={row?.publisherID}
                    onChange={(e) => change(e, row.id)}
                    duplicateNum={getDuplicateNum(
                        row?.publisherID,
                        row?.duplicationsScope
                    )}
                    data={row}
                    borderRadius="0.8rem"
                    height="27px"
                    width="100%"
                    margin="0.5px 0"
                    padding="0 15px 0 0"
                    noBorder
                >
                    {options[row.optionsCategory]?.length &&
                        genericSort(
                            genericSort(
                                options[row.optionsCategory],
                                false,
                                'text',
                                'name'
                            ),
                            false,
                            'text',
                            'surname'
                        ).map((option, i) => {
                            const formattedName = formatName(
                                option,
                                surnameFellows
                            );

                            return (
                                <option key={i} value={option?.publisherID}>
                                    {formattedName}
                                </option>
                            );
                        })}
                </Input>
            );
        } else {
            rowsList.push(
                <Input
                    key={row.id}
                    placeholder={row.placeholder}
                    type={row?.type}
                    maxLength={row.maxlength}
                    value={row.value}
                    onChange={(e) => change(e, row.id)}
                    data={row}
                    borderRadius="0.8rem"
                    height="27px"
                    width="100%"
                    margin="0.5px 0"
                    noBorder
                />
            );
        }
    });

    return (
        !!rowsList.length && (
            <ColumnStyled>
                {rowsList}
                <Button
                    text={t('Pridať stĺpec')}
                    type="green"
                    onClick={post}
                    icon="plus"
                    my="5px"
                />
            </ColumnStyled>
        )
    );
};

export default ScheduleNewColumn;

const ColumnStyled = styled(Box)`
    align-items: center;
    margin: 0px 5px;
    min-width: fit-content;
`;
